<!-- 余额相关 -->
<template>
  <div>
      <!-- 搜索框 -->
      <a-card style="margin-bottom: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator" style="margin-bottom: -8px">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 门店编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店编号:">
                    <a-input
                      v-model="queryParam.facilityCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 门店名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店名称:">
                    <a-input
                      v-model="queryParam.facilityName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 档口编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="档口编号:">
                    <a-input
                      v-model="queryParam.kitchenCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 店铺名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="店铺名称:">
                    <a-input
                      v-model="queryParam.storeName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 客户签约主体 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="客户签约主体:">
                    <a-input
                      v-model="queryParam.licenseeEntityName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24" class="fr">
                  <a-form-item>
                    <a-button type="primary" @click="loadTable()">
                        查询
                    </a-button>
                  </a-form-item>
                  <a-form-item>
                    <a-button @click="resetQueryParam">
                        重置
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
      </a-card>
      <a-card style="margin-bottom: 24px">
        <div class="btnBox">
            <div style="display: flex; align-items: center;">
              <span class="btn-item"><a-button type="primary" @click="onExportCurrentBalance">当前余额导出</a-button></span>
              <span class="btn-item"><a-button type="primary" @click="onExportTransactionHistory">充值/扣费记录导出</a-button></span>
            </div>
            <div v-if="exportFileType === 2">
              <a-popover title="下载任务列表" placement="bottomRight" trigger="click" v-model="showDownRecord">
                <template #content>
                  <div v-for="item in downList" :key="item.id" class="down-item">
                    <div class="down-info">
                      <div class="down-name">{{ item.fileUrl | getFileName }}</div>
                      <div class="down-time">{{ item.time | getFileTime }}</div>
                    </div>
                    <div>
                      <span class="down-btn" @click="openFileItem(item.fileUrl)">打开</span>
                    </div>
                  </div>
                  <div v-if="!downList.length" class="no-down-list">暂无下载任务</div>
                </template>
                <a-button type="primary">下载记录</a-button>
              </a-popover>
            </div>
        </div>
        <!-- table -->
        <a-spin :spinning="dataLoading">
            <a-table
                rowKey="accountId"
                :pagination="pagination"
                :columns="columns"
                :dataSource="tableData"
                :scroll="{ x: 800 }"
                @change="onTableChange"
            >
                <span slot="action" slot-scope="text, record" class="blueColor">
                <a
                    v-if="isCanViewBalanceDetail"
                    @click="viewDetail(record)"
                    style="margin-right:0.8em">
                    查看
                </a>
                <a
                    v-if="record.contractId"
                    @click="onClickRecharge(record,1)"
                    style="margin-right:0.8em">
                    充值
                </a>
                <a
                    v-if="record.contractId"
                    @click="onClickRecharge(record,2)"
                    style="margin-right:0.8em">
                    扣费
                </a>
                </span>
            </a-table>
        </a-spin>
    </a-card>
    <a-modal
        title="当前余额导出"
        :visible="showExportCurrentBalance"
        :confirmLoading="showExportCurrentBalanceLoading"
        @ok="onConfirmExportCurrentBalance"
        @cancel="onCancelExportCurrentBalance"
        destroyOnClose
        >
        <a-row :gutter="30" style="line-height: 4">
            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>城市：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.cityId"
                    placeholder="请选择"
                    @change="onChangeExportCity"
                >
                    <a-select-option v-for="item in userCitiesAll" :key="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-col>

            <a-col :md="24" :sm="24">
                <span class="optional"> </span>
                <span>门店：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.facilityId"
                    allowClear
                    placeholder="请选择"
                >
                    <a-select-option v-for="item in exportAvailableFacility" :key="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>日期范围：</span>
                <a-date-picker
                    class="date-picker"
                    format="YYYY-MM-DD"
                    v-model="exportQuery.startDate"
                    placehholder="选择日期"
                />
            </a-col>
        </a-row>
    </a-modal>
    <a-modal
        title="充值/扣费记录导出"
        :visible="showExportTransactionHistory"
        :confirmLoading="showExportTransactionHistoryLoading"
        @ok="onConfirmExportTransactionHistory"
        @cancel="onCancelExportTransactionHistory"
        destroyOnClose
        >
        <a-row :gutter="30" style="line-height: 4">
            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>选择城市：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.cityId"
                    placeholder="请选择"
                    @change="onChangeExportCity"
                >
                    <a-select-option v-for="item in userCitiesAll" :key="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-col>

            <a-col :md="24" :sm="24">
                <span class="optional"> </span>
                <span>选择门店：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.facilityId"
                    allowClear
                    placeholder="请选择"
                >
                    <a-select-option v-for="item in exportAvailableFacility" :key="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-col>

            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>日期范围：</span>
                <a-date-picker
                    class="date-picker"
                    format="YYYY-MM-DD"
                    v-model="exportQuery.startDate"
                    placehholder="选择开始日期"
                />
                <a-date-picker
                    class="date-picker"
                    format="YYYY-MM-DD"
                    v-model="exportQuery.endDate"
                    placehholder="选择结束日期"
                />
            </a-col>
        </a-row>
    </a-modal>
    <!-- 充值/扣费弹窗 -->
    <a-modal
      :title="rechargeType === 1 ? '充值' : '扣费'"
      :visible="showRechargeInfo"
      :confirmLoading="showRechargeLoading"
      @ok="handleSaveRecharge"
      @cancel="handleCancelRecharge"
      destroyOnClose
    >
      <a-form layout="inline" :form="rechargeForm">
        <a-row :gutter="30" style="line-height: 4">
          <a-col :md="24" :sm="24">
            <a-form-item label="金额">
              <a-input
                style="width: 240px"
                v-decorator="[
                  'balance',
                  {
                    rules: [{ required: true, pattern: /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/, message: '请输入正数最多保留两位小数' }]
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="备注">
              <a-input
                style="width: 240px"
                v-decorator="[
                  'remark',
                  {
                    rules: [{ required: true, message: '请输入备注' }]
                  }
                ]"
                placeholder="请输入备注"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="是否同步金蝶">
              <a-select
                style="width: 240px"
                v-decorator="[
                  'kingdeeSync',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！'
                      }
                    ]
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in smartList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
  
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import { defaultPagination } from "../../../utils/pagination";
import { mapGetters } from "vuex";
import { hasPermissions } from "../../../utils/auth";
import { FacilityActions } from "../../../utils/actions";
export default {
  name: "listBalance",
  props: {
    listBalanceEndpoint: String,
    exportBalanceEndpoint: String,
    exportTransactionEndpoint: String,
    balanceDetailRouteName: String,
    exportFilenamePrefix: String,
    viewDetailPermission: String,
    exportFileType: Number
  },
  data() {
    return {
      // 搜索
      queryParam: {
        facilityCode: "",
        facilityName: "",
        kitchenCode: "",
        storeName: "",
        licenseeEntityName: ""
      },
      // 分页
      pagination: defaultPagination(() => {}),
      // 表头
      columns: [
        {
          title: "城市",
          width: 100,
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "门店编号",
          width: 100,
          dataIndex: "facilityCode",
          key: "facilityCode"
        },
        {
          title: "门店名称",
          width: 160,
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "店铺名称",
          width: 180,
          dataIndex: "storeName",
          key: "storeName"
        },
        {
          title: "客户签约主体",
          width: 220,
          dataIndex: "licenseeEntityName",
          key: "licenseeEntityName",
        },
        {
          title: "当前余额",
          width: 220,
          dataIndex: "balance",
          key: "balance"
        },
        {
          title: "操作",
          key: "action",
          width: 180,
          fixed: "right",
          scopedSlots: { customRender: "action" }
        }
      ],
      // 数据
      tableData: [],
      // dataLoading
      dataLoading: false,

      //导出通用相关
      exportAvailableFacility: [],
      exportQuery: {},

      //当前余额导出相关
      showExportCurrentBalance: false,
      showExportCurrentBalanceLoading: false,

      //充值/扣费记录导出相关
      showExportTransactionHistory: false,
      showExportTransactionHistoryLoading: false,

      current: 1,
      pageSize: 20,

      // 充值/扣费弹窗
      showRechargeInfo: false,
      showRechargeLoading: false,
      rechargeForm: this.$form.createForm(this),
      rechargeType: 1, // 1 充值 2 扣费
      curContractId:null, // 当前的合同Id
      // 下载列表
      downList:[],
      showDownRecord: false,
      // 是否
      smartList: [
        { id: 1, name: "同步" },
        { id: 2, name: "不同步" }
      ],
    };
  },
  filters: {
    getFileName(url) {
      if(!url){
        return '-'
      }
      let urlList = url.split('/') || []
      return urlList[urlList.length -1]
    },
    getFileTime(time){
      if(!time){
        return '-'
      }
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  watch: {
    showDownRecord: {
      deep: true,
      handler(val, oVal) {
        if(val){
          this.onClickDownRecord()
        }
      }
    }
  },
  methods: {
    moment,
    // 重置页面
    resetQueryParam() {
      this.queryParam = {};
      this.loadTable();
    },
    // 分页
    onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadTable();
    },
    loadTable() {
      this.tableData = [];
      
      this.dataLoading = true;
      axios({
        url: this.listBalanceEndpoint,
        method: "GET",
        params: {
          rows: this.pagination.pageSize || 20,
          page: this.pagination.current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.tableData = res.data.rows || [];
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
          this.dataLoading = false;
        } else {
          this.tableData = [];
          this.$message.error(`${res.data.returnMsg}`);
          const pagination = { ...this.pagination };
          pagination.total = null;
          pagination.current = 1;
          this.pagination = pagination;
          this.dataLoading = false;
        }
      });
    },
    // 初始化
    init() {
      this.pagination.current = 1;
      this.pagination.pageSize = 20;

      this.loadTable();
    },

    // 导出函数
    downloadTemplateWithPost(info, url, name) {
      this.$http.post(url, info,{
        responseType: "blob",
      })
      .then(res => {
        if (res.status === 200) {
          this.$message.success("下载成功！");
          const fileName = name + moment().format('YYYYMMDD') + ".xlsx";
          fileDownload(res.data, fileName);
        } else {
          this.$message.error("下载失败！");
        }
      });
    },

    // 跳转详情页面
    viewDetail(val) {
      this.$router.push({
        name: this.balanceDetailRouteName,
        params: {
          contractId: val.contractId,
          accountId: val.accountId,
          balanceAmount: val.balance,
        }
      });
    },

    onChangeExportCity() {
      if (this.exportQuery.cityId === undefined ||
          this.exportQuery.cityId === null) {
        this.exportAvailableFacility = [];
      }
      else {
        axios({
          url: this.$endpoints.FACILITY_LIST_FACILITY_BY_CITY_AND_BRAND,
          method: "GET",
          params: {
            cityIds: this.getCityIdList(this.exportQuery.cityId).join(","),
          }
        })
        .then(res => {
          if (res.data.success) {
            this.exportAvailableFacility = 
              res.data.rows.map(e => {
                return {
                  "id": e.id,
                  "name": e.optionText
                };
              });
          }
          else {
            this.$message.error("获取所选城市对应门店失败，错误信息：" + res.data.returnMsg, 3);
            this.exportAvailableFacility = [];
          }
        })
        .catch(error => {
          this.$message.error("获取所选城市对应门店出错，错误信息：" + error.message, 3);
          this.exportAvailableFacility = [];
        });
      }
    },

    // 当前余额导出相关
    onExportCurrentBalance() {
        this.showExportCurrentBalanceLoading = false;
        this.exportQuery = {};
        this.exportAvailableFacility = [];
        this.showExportCurrentBalance = true;
    },

    onConfirmExportCurrentBalance() {
        if (this.exportQuery.cityId === undefined || 
            this.exportQuery.cityId === null) {
            this.$message.error("请选择城市", 3);
            return;
        }
        if (this.exportQuery.startDate === undefined ||
            this.exportQuery.startDate === null) {
            this.$message.error("请选择起始日期", 3);
            return;
        }

        this.showExportCurrentBalanceLoading = true;

        const queryData = {
            'cityIdList': this.getCityIdList(this.exportQuery.cityId),
            'facilityIdList': this.exportQuery.facilityId ? [this.exportQuery.facilityId] : null,
            'startDate': this.exportQuery.startDate.format("YYYY-MM-DD"),
        }

      const exportLicenseeCurrentBalance = "export/file/exportLicenseeCurrentBalance";
      const exportUtilityCurrentBalance = "export/file/exportUtilityCurrentBalance";

      this.$http.post(this.exportFileType === 2 ? exportUtilityCurrentBalance : exportLicenseeCurrentBalance, queryData).then((res) => {
        if (res.status === 200) {
          this.$message.success("提交导出任务成功，请稍后到导出文件下载");
        } else {
          this.$message.error("导出失败！" + res.data);
        }
        this.$router.push({ path: "/statistics/files" });
      });

        // this.downloadTemplateWithPost(
        //   queryData,
        //   this.exportBalanceEndpoint,
        //   this.exportFilenamePrefix + "当前余额"
        // );

        setTimeout(() => {
            this.showExportCurrentBalanceLoading = false;
            this.showExportCurrentBalance = false;
        }, 1000);
    },

    onCancelExportCurrentBalance() {
        this.showExportCurrentBalanceLoading = false;
        this.showExportCurrentBalance = false;
    },

    // 充值/扣费记录导出
    onExportTransactionHistory() {
        this.showExportTransactionHistoryLoading = false;
        this.exportQuery = {};
        this.exportAvailableFacility = [];
        this.showExportTransactionHistory = true;
    },

    onConfirmExportTransactionHistory() {
        if (this.exportQuery.cityId === undefined || 
            this.exportQuery.cityId === null) {
            this.$message.error("请选择城市", 3);
            return;
        }

        if (this.exportQuery.startDate === undefined ||
            this.exportQuery.startDate === null) {
            this.$message.error("请选择起始日期", 3);
            return;
        }

        if (this.exportQuery.endDate === undefined ||
            this.exportQuery.endDate === null) {
            this.$message.error("请选择结束日期", 3);
            return;
        }

        if (this.exportQuery.endDate < this.exportQuery.startDate) {
            this.$message.error("结束日期早于起始日期", 3);
            return;
        }

        this.showExportTransactionHistoryLoading = true;
        const queryData = {
            cityIdList: this.getCityIdList(this.exportQuery.cityId),
            facilityIdList: this.exportQuery.facilityId ? [this.exportQuery.facilityId] : null,
            startDate: this.exportQuery.startDate.format("YYYY-MM-DD"),
            endDate: this.exportQuery.endDate.format("YYYY-MM-DD"),
        }
        if(this.exportFileType === 2){
          this.exportUtilityBalanceHistory(queryData);
          return;
        }

        // this.downloadTemplateWithPost(
        //   queryData,
        //   this.exportTransactionEndpoint,
        //   this.exportFilenamePrefix + "交易记录"
        // );
        this.$http.post("export/file/exportLicenseeBalanceTransactionHistory", queryData).then((res) => {
          if (res.status === 200) {
            this.$message.success("提交导出任务成功，请稍后到导出文件下载");
          } else {
            this.$message.error("导出失败！" + res.data);
          }
          this.$router.push({ path: "/statistics/files" });
        });
        setTimeout(() => {
            this.showExportTransactionHistoryLoading = false;
            this.showExportTransactionHistory = false;
        }, 1000);
    },

    onCancelExportTransactionHistory() {
        this.showExportTransactionHistoryLoading = false;
        this.showExportTransactionHistory = false;
    },

    getCityIdList(cityId) {
      let cityIds = [];
      if (cityId == 0) {
        cityIds = this.userCities.map(e => e.id);
      }
      else {
        cityIds = [cityId];
      }

      return cityIds;
    },

    // 点击充值或扣费 type:1充值 2：扣费
    onClickRecharge(val,type){
      this.curContractId = val.contractId
      this.rechargeType = type;
      this.showRechargeInfo = true;
    },
    // 更换确认函数
    handleSaveRecharge() {
      let that = this;
      const {
        rechargeForm: { validateFields }
      } = this;
      this.showRechargeLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.submitRechargeInfo(values);
        } else {
          this.showRechargeLoading = false;
        }
      });
    },
    // 更换提交函数
    submitRechargeInfo(val) {
      let params = {
        balance:this.rechargeType === 1 ? val.balance * 1 : val.balance * -1,
        remark:val.remark,
        contractId:this.curContractId,
        type:this.exportFileType,
        kingdeeSync:val.kingdeeSync
      }
      axios({
        method: "POST",
        url:   this.$baseUrl + "ss/Ops/update-utilities-account-balance",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...params
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.loadTable();
            this.$message.success( this.rechargeType === 1 ? "充值成功！" : "扣费成功！");
            this.showRechargeInfo = false;
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.showRechargeLoading = false;
          this.curContractId = null
        })
        .catch(error => {
          this.$message.error(error);
          this.showRechargeLoading = false;
          this.curContractId = null;
        });
    },
    // 取消弹窗
    handleCancelRecharge() {
      this.showRechargeInfo = false;
      this.showRechargeLoading = false;
      this.curContractId = null;
    },

    // 展示下载记录
    onClickDownRecord(visible) {
      axios({
        method: "POST",
        url: this.$baseUrl + "finance/balance/getUtilityBalanceTransactionHistoryDownloadFile",
        headers: {
          Accept: "*/*"
        },
        data: {}
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.downList = res.data.obj
            this.showDownRecord = visible;
          } else {
            this.$message.error(`${res.data.returnMsg}`);
            this.showDownRecord = visible;
          }
        })
        .catch(error => {
          this.$message.error(error);
        });
    },
    // 导出函数
    exportUtilityBalanceHistory(info) {
      axios({
        method: "POST",
        url: this.$baseUrl + "finance/balance/exportUtilityBalanceTransactionHistoryV2",
        headers: {
          Accept: "*/*"
        },
        data: {...info}
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.$message.success("生成中，请到下载记录查看");
            this.showExportTransactionHistoryLoading = false;
            this.showExportTransactionHistory = false;
          } else {
            this.$message.error(`${res.data.returnMsg}`);
            this.showExportTransactionHistoryLoading = false;
            this.showExportTransactionHistory = false;
          }
        })
        .catch(error => {
          this.$message.error(error);
          this.showExportTransactionHistoryLoading = false;
          this.showExportTransactionHistory = false;
        });
    },
    openFileItem(url){
      window.open(url, "_blank");
    }
  },
  activated() {
    if (this.$route.query.reset) {
      this.init();
    }
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    ...mapGetters("cities", ["userCities", "userCitiesAll"]),
    isCanViewBalanceDetail() {
      return hasPermissions(
        [this.viewDetailPermission],
        this.authenticatedUser.actions
      );
    }
  }
};
</script>
<style lang="less">
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ml15 {
  margin-left: 1.5em;
}
.btn-item {
  margin-right: 16px;
  margin-bottom: 10px;
}
.mandatory {
    display: inline-block;
    width: 15px;
    color: red;
    margin-right: 3px;
    text-align: right;
}
.optional {
    display: inline-block;
    width: 15px;
    margin-right: 3px;
    text-align: right;
}
.date-picker {
    margin-right: 15px;
}
.btnBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.down-item{
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
  .down-info{
    flex: 1;
    overflow: hidden;
  }
  .down-name{
    width: 100%;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .down-time{
    color: #ccc;
    font-size: 12px;
  }
  .down-status{
    font-size: 14px;
    color: #ccc;
  }
  .down-btn{
    font-size: 14px;
    cursor: pointer;
    color: #1890ff;
    width: 50px;
    text-align: right;
  }
}
.no-down-list{
  width: 100%;
  text-align: center;
  color: #666;
  padding: 30px 0;
  background-color: transparent !important;
}
</style>
